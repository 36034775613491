import {
  AppBar,
  Box,
  Button,
  CircularProgress,
  Container,
  Snackbar,
  Toolbar,
  Typography,
} from "@material-ui/core"
import Alert from "@material-ui/lab/Alert"
import * as anchor from "@project-serum/anchor"
import { WalletDialogButton } from "@solana/wallet-adapter-material-ui"
import { useAnchorWallet } from "@solana/wallet-adapter-react"
import { LAMPORTS_PER_SOL } from "@solana/web3.js"
import { useEffect, useState } from "react"
import Countdown from "react-countdown"
import styled from "styled-components"
import {
  awaitTransactionSignatureConfirmation,
  CandyMachine,
  getCandyMachineState,
  mintOneToken,
  shortenAddress,
} from "./candy-machine"

const ConnectButton = styled(WalletDialogButton)`
  background: #d86c55;
  border-radius: 4px;
  padding: 0px 16px;
  font-family: Passion One;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  text-align: center;
  :hover,
  :active {
    background: #ef775e;
    opacity: 0.8;
  }
`

const CounterText = styled.span`` // add your styles here

const MintContainer = styled.div`
  text-align: center;
` // add your styles here

const MintButton = styled(Button)`
  background: #cd231f;
  color: #ffffff;
  box-shadow: inset 0px -2px 0px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  font-size: 30px;
  margin-top: -5px;
  :disabled {
    background: #cd231f;
  }
  :hover,
  :active {
    background: #dd2824;
    opacity: 0.8;
  }
` // add your styles here

const InfoButton = styled(Button)`
  background: #d86c55;
  border-radius: 4px;
  padding: 0px 16px;
`

export interface HomeProps {
  candyMachineId: anchor.web3.PublicKey
  config: anchor.web3.PublicKey
  connection: anchor.web3.Connection
  startDate: number
  treasury: anchor.web3.PublicKey
  txTimeout: number
}

const Home = (props: HomeProps) => {
  const [balance, setBalance] = useState<number>()
  const [isActive, setIsActive] = useState(false) // true when countdown completes
  const [isSoldOut, setIsSoldOut] = useState(false) // true when items remaining is zero
  const [isMinting, setIsMinting] = useState(false) // true when user got to press MINT

  const [itemsAvailable, setItemsAvailable] = useState(0)
  const [itemsRemaining, setItemsRemaining] = useState(0)

  const [alertState, setAlertState] = useState<AlertState>({
    open: false,
    message: "",
    severity: undefined,
  })

  const [startDate, setStartDate] = useState(new Date(props.startDate))

  const wallet = useAnchorWallet()
  const [candyMachine, setCandyMachine] = useState<CandyMachine>()

  const refreshCandyMachineState = () => {
    ;(async () => {
      if (!wallet) return

      const { candyMachine, goLiveDate, itemsAvailable, itemsRemaining } =
        await getCandyMachineState(
          wallet as anchor.Wallet,
          props.candyMachineId,
          props.connection
        )

      setItemsAvailable(itemsAvailable)
      setItemsRemaining(itemsRemaining)

      setIsSoldOut(itemsRemaining === 0)
      setStartDate(goLiveDate)
      setCandyMachine(candyMachine)
    })()
  }

  const onMint = async () => {
    try {
      setIsMinting(true)
      if (wallet && candyMachine?.program) {
        const mintTxId = await mintOneToken(
          candyMachine,
          props.config,
          wallet.publicKey,
          props.treasury
        )

        const status = await awaitTransactionSignatureConfirmation(
          mintTxId,
          props.txTimeout,
          props.connection,
          "singleGossip",
          false
        )

        if (!status?.err) {
          setAlertState({
            open: true,
            message: "Congratulations! Mint succeeded!",
            severity: "success",
          })
        } else {
          setAlertState({
            open: true,
            message: "Mint failed! Please try again!",
            severity: "error",
          })
        }
      }
    } catch (error: any) {
      // TODO: blech:
      let message = error.msg || "Minting failed! Please try again!"
      if (!error.msg) {
        if (error.message.indexOf("0x138")) {
        } else if (error.message.indexOf("0x137")) {
          message = `SOLD OUT!`
        } else if (error.message.indexOf("0x135")) {
          message = `Insufficient funds to mint. Please fund your wallet.`
        }
      } else {
        if (error.code === 311) {
          message = `SOLD OUT!`
          setIsSoldOut(true)
        } else if (error.code === 312) {
          message = `Minting period hasn't started yet.`
        }
      }

      setAlertState({
        open: true,
        message,
        severity: "error",
      })
    } finally {
      if (wallet) {
        const balance = await props.connection.getBalance(wallet.publicKey)
        setBalance(balance / LAMPORTS_PER_SOL)
      }
      setIsMinting(false)
      refreshCandyMachineState()
    }
  }

  useEffect(() => {
    ;(async () => {
      if (wallet) {
        const balance = await props.connection.getBalance(wallet.publicKey)
        setBalance(balance / LAMPORTS_PER_SOL)
      }
    })()
  }, [wallet, props.connection])

  useEffect(refreshCandyMachineState, [
    wallet,
    props.candyMachineId,
    props.connection,
  ])

  return (
    <Container fixed>
      <Box
        textAlign='center'
        style={{
          backgroundImage: `url(/pixel-factory.gif)`,
          width: "100%",
          height: "640px",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
        }}
        pb={8}
      >
        <AppBar position='static' color='transparent' elevation={0}>
          <Toolbar>
            <Box sx={{ flexGrow: wallet ? 1.2 : 1.4 }} />
            <Typography variant='h1'>Festive Elves</Typography>
            <Box sx={{ flexGrow: 1 }} />
            {!wallet ? (
              <ConnectButton>Connect wallet</ConnectButton>
            ) : (
              <p>{shortenAddress(wallet.publicKey.toBase58() || "")}</p>
            )}
          </Toolbar>
        </AppBar>
      </Box>
      <Box textAlign='center' mt='-60px'>
        <img src='/Shape.png' alt='Ribbon' />
      </Box>
      <MintContainer>
        <MintButton
          disabled={!wallet || isSoldOut || isMinting || !isActive}
          onClick={onMint}
          variant='contained'
        >
          {isSoldOut ? (
            "SOLD OUT"
          ) : isActive ? (
            isMinting ? (
              <CircularProgress />
            ) : (
              "Mint ~0.02 SOL"
            )
          ) : (
            <Countdown
              date={startDate}
              onMount={({ completed }) => completed && setIsActive(true)}
              onComplete={() => setIsActive(true)}
              renderer={renderCounter}
            />
          )}
        </MintButton>
        {wallet && (
          <Box p={4}>
            <Box component='span' p={2}>
              <InfoButton>
                {itemsRemaining} / {itemsAvailable} left
              </InfoButton>
            </Box>
            <Box component='span' p={2}>
              <InfoButton>
                Balance: {(balance || 0).toLocaleString()} ◎
              </InfoButton>
            </Box>
          </Box>
        )}
      </MintContainer>
      <Snackbar
        open={alertState.open}
        autoHideDuration={6000}
        onClose={() => setAlertState({ ...alertState, open: false })}
      >
        <Alert
          onClose={() => setAlertState({ ...alertState, open: false })}
          severity={alertState.severity}
        >
          {alertState.message}
        </Alert>
      </Snackbar>
    </Container>
  )
}

interface AlertState {
  open: boolean
  message: string
  severity: "success" | "info" | "warning" | "error" | undefined
}

const renderCounter = ({ days, hours, minutes, seconds, completed }: any) => {
  return (
    <CounterText>
      {hours + (days || 0) * 24} hours, {minutes} minutes, {seconds} seconds
    </CounterText>
  )
}

export default Home
